<template>
  <div class="discount-main-container">
    <div class="discount-has-ticket flex-column-cen">
      <div
        class="discount-item flex-cen"
        v-for="item in discountList"
        :key="item.id"
      >
        <div class="discount-img-wrap">
          <img src="@/assets/img/icon/img02.png" alt="" />
        </div>
        <div class="discount-content-wrap">
          <div class="discount-content-top flex-bet">
            <div class="discount-content-left">
              <div class="discount-content-title font-medium">
                {{
                  $trans(
                    `coupon-${item.promotionId}.name`,
                    item.promotionName
                  ) || '上殊房优惠券'
                }}
              </div>
              <div class="discount-content-time font-regular">
                {{ item.validBegin | normalizeDate }}~{{
                  item.validEnd | normalizeDate
                }}
              </div>
            </div>
            <div
              class="discount-content-right flex font-medium"
              v-if="
                item.type == '11' ||
                item.type == '33' ||
                item.type == '55' ||
                item.type == '77'
              "
            >
              <div class="discount-content-tip">{{ item.value * 10 }}折</div>
              <!-- <div class="discount-content-tip">折</div> -->
            </div>
            <div class="discount-content-right flex font-medium" v-else>
              <div class="discount-content-tip">{{ item.value }}元</div>
            </div>
          </div>
          <div class="discount-content-hr"></div>
          <div class="flex-bet-cen">
            <div class="discount-content-describe">
              {{ item.showText }}
            </div>
            <span class="immediate-use font-medium">{{
              $trans('已过期', '已过期')
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { loadLocaleMessages } from '@/i18n/i18n'
import { myCouponHandle } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      discountList: [], // 优惠券
      DIRCTIONARIES: {
        // 字典
        11: '全场',
        33: '全场',
        55: '部分商品',
        77: '部分商品',
      },
    }
  },
  created() {
    this.myCouponHandle()
  },
  methods: {
    myCouponHandle() {
      let params = {
        userId: this.$store.state.userId,
        couponStatus: 3,
      }
      myCouponHandle(params).then((res) => {
        if (res.code == '200') {
          this.discountList = res.data.rows
            ? JSON.parse(JSON.stringify(res.data.rows))
            : []
          this.discountList.forEach((item) => {
            if (item.transferList) {
              loadLocaleMessages(
                item.transferList,
                `coupon-${item.promotionId}`
              )
            }
          })
          this.couponVoPayList()
        }
      })
    },
    // 优惠券完整列表
    couponVoPayList() {
      this.discountList.forEach((item) => {
        if (item.type == 11) {
          item.showText =
            `${this.$trans('全场', '全场')} ` +
            item.value * 10 +
            ` ${this.$trans('折', '折')}`
        } else if (item.type == 22) {
          item.showText =
            `${this.$trans('全场减', '全场减')} ` +
            item.value +
            ` ${this.$trans('元', '元')}`
        } else if (item.type == 33) {
          item.showText =
            `${this.$trans('全场满', '全场满')} ` +
            item.conditionAmount +
            ` ${this.$trans('元打', '元打')} ` +
            item.value * 10 +
            ` ${this.$trans('折', '折')}`
        } else if (item.type == 44) {
          item.showText =
            `${this.$trans('全场满', '全场满')} ` +
            item.conditionAmount +
            ` ${this.$trans('元减', '元减')} ` +
            item.value +
            ` ${this.$trans('元', '元')}`
        } else if (item.type == 55) {
          item.showText =
            `${this.$trans('部分商品', '部分商品')} ` +
            item.value * 10 +
            ` ${this.$trans('折', '折')}`
        } else if (item.type == 66) {
          item.showText =
            `${this.$trans('部分商品减', '部分商品减')} ` +
            item.value +
            ` ${this.$trans('元', '元')}`
        } else if (item.type == 77) {
          item.showText =
            `${this.$trans('部分商品满', '部分商品满')} ` +
            item.conditionAmount +
            ` ${this.$trans('元打', '元打')} ` +
            item.value * 10 +
            ` ${this.$trans('折', '折')}`
        } else if (item.type == 88) {
          item.showText =
            `${this.$trans('部分商品满', '部分商品满')} ` +
            item.conditionAmount +
            ` ${this.$trans('元减', '元减')} ` +
            item.value +
            ` ${this.$trans('元', '元')}`
        }
      })
    },
  },
}
</script>
<style scoped>
.discount-not-ticket {
  height: 500px;
  justify-content: center;
}
.not-ticket-img {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}
.discount-item {
  background: url('../../assets/img/icon/back01.png');
  margin: 0px 12px 12px 12px;
  height: 110px;
  width: 500px;
}
.discount-img-wrap {
  height: 110px;
  width: 110px;
  flex-shrink: 0;
}
.discount-content-left {
  position: relative;
}
.discount-content-wrap {
  padding: 30px 25px 15px 10px;
  height: 100%;
  flex-grow: 1;
  color: #8f8f8f;
}
.discount-content-top {
  align-items: flex-end;
}
.discount-content-title {
  font-size: 12px;
  margin-bottom: 20px;
}
.discount-content-time {
  color: #8f8f8f;
  font-size: 16px;
  transform-origin: left;
  transform: scale(0.5);
  position: absolute;
  bottom: -10px;
  white-space: nowrap;
}
.discount-content-right {
  align-items: flex-end;
}
.discount-content-int {
  font-size: 24px;
  font-weight: bold;
}
.discount-content-tip {
  font-size: 15px;
}
.discount-content-hr {
  margin-top: 15px;
  width: 100%;
  height: 1px;
  background: #dddddd;
  margin-bottom: 6px;
}
.discount-content-describe {
  font-size: 12px;
  transform-origin: left;
  transform: scale(0.6);
}
.immediate-use {
  font-size: 12px;
}
</style>
